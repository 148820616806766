import * as THREE from "three";
import vertex from "./svgShader/vertex.glsl";
import fragment from "./svgShader/fragment.glsl";

const SvgParticles = (svgId, meshPosition) => {
  // Get data
  let maxPathLength = 0;
  const getData = () => {
    const svg = document.getElementById(svgId);

    const paths = [...svg.querySelectorAll("path")];

    const viewBox = svg.getAttribute("viewBox").split(" ");
    const viewBoxWidth = parseFloat(viewBox[2]);
    const viewBoxHeight = parseFloat(viewBox[3]);

    let lines = [];

    paths.forEach((path) => {
      if (path.getTotalLength() > maxPathLength) {
        maxPathLength = path.getTotalLength();
      }
    });

    paths.forEach((path, pathIndex) => {
      let pathLenght = path.getTotalLength();
      //   let numberOfPoints = path.getTotalLength() / maxPathLength;

      let line = [];

      for (let i = 0; i <= maxPathLength; i++) {
        let pointAt = pathLenght * (i / maxPathLength);
        let point = path.getPointAtLength(pointAt);

        point.x = point.x - viewBoxWidth / 2;
        point.y = -(point.y - viewBoxHeight / 2);
        line.push(new THREE.Vector3(point.x, point.y, 0));
      }

      lines.push({
        id: pathIndex,
        path: path,
        length: pathLenght,
        number: maxPathLength,
        points: line,
        currentPosition: 0,
        speed: 1,
      });
    });
    return lines;
  };

  const lines = getData();

  let positions = new Float32Array(3 * maxPathLength * lines[0].points.length);
  positions.set(0);

  let j = 0;
  lines.forEach((line) => {
    for (let i = 0; i < line.points.length; i++) {
      positions.set(
        [0, 0, 0],

        j * 3
      );
      j++;
    }
  });

  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));
  geometry.computeBoundingSphere();
  const material = new THREE.ShaderMaterial({
    vertexShader: vertex,
    fragmentShader: fragment,
    side: THREE.DoubleSide,
    transparent: true,
    depthWrite: true,
    blending: THREE.AdditiveBlending,
    uniforms: {
      uTime: { type: "f", value: 0 },
    },
  });

  const mesh = new THREE.Points(geometry, material);
  mesh.position.set(...meshPosition);

  j = 0;
  let i = 0;
  let zeroTime = 0;
  const clock = new THREE.Clock();

  const animate = () => {
    const elapsedTime = clock.getElapsedTime();

    if (elapsedTime - zeroTime > 0.01 && i < maxPathLength) {
      zeroTime = elapsedTime;
      lines.forEach((line) => {
        positions.set([line.points[i].x, line.points[i].y, 0], j * 3);

        j++;
      });
      i++;
      mesh.geometry.getAttribute("position").array = positions;
      mesh.geometry.getAttribute("position").needsUpdate = true;
    }

    requestAnimationFrame(animate);
  };
  animate();

  return mesh;
};
export default SvgParticles;
