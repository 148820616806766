import React, { useEffect } from "react";
import "./datePicker.css";
import useFetchAndDisplay from "../utils/useFetchAndDisplay";
const DatePicker = () => {
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const years = Array.from({ length: 8 }, (_, i) => i + 2015);

  const prepareDateForFetch = () => {
    const day = document.getElementById("day").value;
    const month = document.getElementById("month").value;
    const year = document.getElementById("year").value;

    return `${year}-${month}-${day}`;
  };
  const fetchDataAndDisplay = useFetchAndDisplay();
  const handleClick = (e) => {
    e.preventDefault();
    const date = prepareDateForFetch();
    fetchDataAndDisplay(date);
  };
  useEffect(() => {
    document
      .getElementById("datePickerBtn")
      .addEventListener("click", handleClick);
  }, []);

  return (
    <div className="selectors datePicker">
      <div className="visibleDateBox">
        <div className="daysBox">
          <select id="day" name="day">
            {days.map((day) => (
              <option key={day} value={day}>
                {day}
              </option>
            ))}
          </select>
        </div>
        <div className="selector monthsBox">
          <select id="month" name="month">
            {months.map((month, index) => (
              <option key={index} value={index + 1}>
                {month}
              </option>
            ))}
          </select>
        </div>
        <div className="selector yearsBox">
          <select id="year" name="year">
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="btn-div" id="datePickerBtn">
        <p>Lest Go!</p>
      </div>
    </div>
  );
};

export default DatePicker;
