import * as THREE from "three";
import bgVertex from "./starsBgShaders/vertex.glsl";
import bgFragment from "./starsBgShaders/fragment.glsl";

const StarsBg = (canvas, numberOfStars = 200) => {
  const bounds = canvas.getBoundingClientRect();

  let starsPositions = new Float32Array(numberOfStars * 3);

  for (let i = 0; i < numberOfStars; i++) {
    starsPositions.set(
      [
        (Math.random() - 1 / 2) * bounds.width,
        (Math.random() - 1 / 2) * bounds.height,
        Math.random() - 10,
      ],
      i * 3
    );
  }

  const bgGeometry = new THREE.BufferGeometry();
  bgGeometry.setAttribute(
    "position",
    new THREE.BufferAttribute(starsPositions, 3)
  );

  const bgMaterial = new THREE.ShaderMaterial({
    fragmentShader: bgFragment,
    vertexShader: bgVertex,
    uniforms: {
      uTime: { value: 0 },
    },
  });
  const bgMesh = new THREE.Points(bgGeometry, bgMaterial);
  return bgMesh;
};

export default StarsBg;
