import { createSlice } from "@reduxjs/toolkit";

const gallerySlice = createSlice({
  name: "gallery",
  initialState: {
    array: JSON.parse(localStorage.getItem("localGalleryFromNasa")) || [],
  },
  reducers: {
    addToGallery: (state, action) => {
      console.log(action.payload.date);
      const isAlreadySaved = state.array.some(
        (item) => item.date === action.payload.date
      );

      if (!isAlreadySaved) {
        state.array.push(action.payload);
        localStorage.setItem(
          "localGalleryFromNasa",
          JSON.stringify(state.array)
        );
        alert("Saved");
      } else {
        alert("Already Saved!!!");
      }
    },
    removeFromGallery: (state, action) => {
      state.array = state.array.filter((item) => item.date !== action.payload);
      localStorage.setItem("localGalleryFromNasa", JSON.stringify(state.array));
      const card = document.querySelector("#action.payload");
      if (card) {
        card.remove();
      }
    },
  },
});

export const { addToGallery, removeFromGallery } = gallerySlice.actions;
export default gallerySlice.reducer;
