import DatePicker from "./DatePicker";
import { useDispatch, useSelector } from "react-redux";
import { removeFromGallery } from "../store/gallerySlice";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "./mainHtml.css";
import useFetchAndDisplay from "../utils/useFetchAndDisplay";
import { setRendererState } from "../store/renderesSlice";
import { useEffect } from "react";
import { click } from "@testing-library/user-event/dist/click";

const MainHtml = () => {
  const dispatch = useDispatch();
  const rendereState = useSelector((state) => state.renderers.rendererGallexy);

  // const corsAnywhereUrl = "https://cors-anywhere.herokuapp.com/";
  const corsproxy = "https://corsproxy.io/?";

  // Remove From Gallery
  const handleRemoveFromStorage = (event) => {
    let imageDate = event.currentTarget.dataset.date;
    dispatch(removeFromGallery(imageDate));
  };

  const galleryArray = [...useSelector((state) => state.gallery.array)];
  const fetchAndDisplay = useFetchAndDisplay();
  const closeInf = () => {
    document.getElementById("info").classList.add("displayNone");
    document
      .getElementById("samplereParticlesCanvas")
      .classList.add("displayNone");
    document.getElementById("info").classList.remove("show");
    dispatch(setRendererState({ renderer: "rendererMain", value: true }));
  };

  const openInf = () => {
    document
      .getElementById("samplereParticlesCanvas")
      .classList.remove("displayNone");
    document.getElementById("info").classList.remove("displayNone");
    document.getElementById("info").classList.add("show");

    dispatch(setRendererState({ renderer: "rendererMain", value: false }));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    let thumbDelBtans = [];
    let imgs = [];

    thumbDelBtans = document.querySelectorAll(".thumbDel");
    thumbDelBtans.forEach((btn) =>
      btn.addEventListener("click", handleRemoveFromStorage)
    );

    imgs = document.querySelectorAll(".saved-image");
    imgs.forEach((img) =>
      img.addEventListener("click", (e) =>
        fetchAndDisplay(e.currentTarget.dataset.date)
      )
    );

    const closeInfBtn = document.getElementById("closeInfBtn");
    closeInfBtn.addEventListener("click", closeInf);

    const openInfBtn = document.getElementById("openInfBtn");
    openInfBtn.addEventListener("click", openInf);

    return () => {
      thumbDelBtans.forEach((btn) =>
        btn.removeEventListener("click", handleRemoveFromStorage)
      );
    };
  }, [galleryArray]);

  return (
    <>
      <div id="main">
        <section id="heroSection" className="heroSection">
          <div className="wrapper">
            <div className="container">
              <div className="heroText">
                <h1 className="heroTile inFront ">
                  Embark on a Cosmic Journey
                </h1>
                <h3 className="heroDescription inFront">
                  Select a date to reveal a mesmerizing picture of the cosmos
                </h3>
              </div>
              <div className="heroDate">
                <DatePicker />
              </div>
            </div>
          </div>
        </section>
        <section id="gallerySection" className="gallerySection">
          <div className="wrapper">
            <div className="container">
              <div className="sectionTitle">
                <h1>Your Gallery</h1>
              </div>

              {galleryArray.length === 0 ? (
                <div className="cards noSavedImg" id="cards">
                  <h2>
                    You don't have any saved pictures in your gallery yet!
                  </h2>
                </div>
              ) : (
                <div className="cards" id="cards">
                  {galleryArray.map((item) => {
                    if (item.url.endsWith(".jpg")) {
                      return (
                        <div className="card" id={item.date} key={item.date}>
                          <div className="imgContainer">
                            <div className="thumbDel" data-date={item.date}>
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                            <img
                              className="saved-image"
                              id={`img-${item.date}`}
                              src={corsproxy + item.url}
                              alt={item.title}
                              data-date={item.date}
                            />
                          </div>
                          <div className="imgDate">
                            <p>{item.date}</p>
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              )}
            </div>
          </div>
          <div className="infOpenBtn" id="openInfBtn">
            !
          </div>
        </section>
      </div>
      <section className="info displayNone" id="info">
        <div className="infoWrapper">
          <div className="infoContainer">
            <div className="closeInf" id="closeInfBtn">
              <FontAwesomeIcon icon={faXmark} />
            </div>
            <p className="line-1">Hi, this is Keivan!</p>
            <p className="line-2">Welcome to my App</p>
            <div className="particleSampler" id="samplerParticlesDiv"></div>
            <p className="line-3">
              This website is built with React as the framework, and it utilizes
              the power of WebGL and JavaScript to create an immersive
              experience with interactive components, including a mesmerizing
              galaxy and other captivating effects.
            </p>
            <p className="line-4">I hope you enjoy it.</p>
            <p className="line-5">
              You can reach me at kaze0017@algonquinlive.com
            </p>
          </div>
        </div>
      </section>

      {/* SVG */}
      <svg
        id="aries"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 115.71 77.75"
      >
        <path
          d="M18.72,54.76L87.14,45.9L89.19,27.18L34.04,13.56L23.14,16.29L18.72,54.76Z"
          className="cls-1"
        />
        <path d="M0.34,71.44L18.72,54.76L9.53,77.56" className="cls-1" />
        <path d="M114.72,50.33L87.14,45.9L104.85,60.2" className="cls-1" />
        <path
          d="M114.72,39.1L105.87,19.01L89.19,27.18L114.72,39.1"
          className="cls-1"
        />
        <path
          d="M24.17,1.31L34.04,13.56L23.14,16.29L24.17,1.31"
          className="cls-1"
        />
      </svg>

      <svg
        id="sagittarius"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 147.97 159.65"
      >
        <path
          className="cls-1"
          d="M121.01,39.7L105.72,51.17L75.65,68.62L64.35,75.6L27.62,85.9L0.04,88.06"
        />
        <path
          className="cls-1"
          d="M144.11,114.48L115.69,112.98L100.07,150.7L125.83,159.18"
        />
        <path
          className="cls-1"
          d="M27.62,85.9L40.42,61.47L64.35,75.6L65.01,98.36L100.07,150.7"
          fill="none"
        />
        <path className="cls-1" d="M115.69,112.98L82.13,89.55L75.65,68.62" />
        <path
          className="cls-1"
          d="M146.93,73.44L112.04,72.44L105.72,51.17L86.11,31.23L95.67,1.15L121.01,39.7L146.93,73.44"
        />
      </svg>

      <svg
        id="gemini"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 87.83 102.47"
      >
        <path
          className="cls-1"
          d="M0,17.7L14.98,14.64,29.62,17.7,48.34,16.17,75.57,9.19"
        />
        <path
          className="cls-1"
          d="M64.34,84.43L60.26,51.74,48.34,16.17,37.79,0"
        />
        <path className="cls-1" d="M87.83,79.66L75.57,76.6,60.26,51.74" />
        <path
          className="cls-1"
          d="M10.89,5.45L14.98,42.21,1.7,65.7,26.55,102.47"
        />
        <path className="cls-1" d="M46.64,96.34L30.64,62.98,14.98,42.21" />
      </svg>
      <footer></footer>
    </>
  );
};
export default MainHtml;
