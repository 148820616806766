import { configureStore } from "@reduxjs/toolkit";
import dataReducer from "./dataSlice";
import galleryReducer from "./gallerySlice";
import renderersReducer from "./renderesSlice";

const store = configureStore({
  reducer: {
    data: dataReducer,
    gallery: galleryReducer,
    renderers: renderersReducer,
  },
});

export default store;
