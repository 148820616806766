import { createSlice } from "@reduxjs/toolkit";

const dataSlice = createSlice({
  name: "data",
  initialState: {
    title: "",
    url: "",
    hdurl: "",
    explanation: "",
    date: "",
  },
  reducers: {
    setData: (state, action) => {
      state.title = action.payload.title;
      state.url = action.payload.url;
      state.hdurl = action.payload.hdurl;
      state.explanation = action.payload.explanation;
      state.date = action.payload.date;
    },
    delData: (state) => {
      state.title = {};
    },
  },
});

export const { setData, delData } = dataSlice.actions;
export default dataSlice.reducer;
