import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rendererMain: true,
  rendererInfo: false,
};

const renderersSlice = createSlice({
  name: "renderers",
  initialState,
  reducers: {
    setRendererState: (state, action) => {
      const { renderer, value } = action.payload;
      state[renderer] = value;
    },
  },
});

export const { setRendererState } = renderersSlice.actions;

export default renderersSlice.reducer;
