import * as THREE from "three";
import vertexShader from "./galaxyShaders/vertex.glsl";
import fragmentShader from "./galaxyShaders/fragment.glsl";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
/**
 * Base
 */

// Canvas

const Galaxy = () => {
  const sceneRef = useRef(null);
  const rendererRef = useRef(null);
  const cameraRef = useRef(null);
  const canvasRef = useRef(null);
  const heroRef = useRef(null);
  const cameraDistance = 500;
  const rendereState = useSelector((state) => state.renderers.rendererMain);
  useEffect(() => {
    // Environment ************************************

    // Canvas
    canvasRef.current = document.getElementById("heroSectionCanvas");
    heroRef.current = document.getElementById("heroSection");
    const heroBounds = heroRef.current.getBoundingClientRect();
    setCanvas(heroBounds);

    // Renderer

    rendererRef.current = new THREE.WebGLRenderer({
      canvas: canvasRef.current,
    });
    setRenderer(heroBounds);
    rendererRef.current.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    // Scene
    sceneRef.current = new THREE.Scene();

    // Camera
    cameraRef.current = new THREE.PerspectiveCamera(
      75,
      heroBounds.width / heroBounds.height,
      0.1,
      1200
    );

    const cameraDistance = 600;
    setCamera(heroBounds);

    sceneRef.current.add(cameraRef.current);

    // ******************************************

    //  Galaxy***********************************
    const parameters = {};
    parameters.count = 200000;
    parameters.size = 10;
    parameters.radius = 550;
    parameters.branches = 3;
    parameters.spin = 0.4;
    parameters.randomness = 0.2;
    parameters.randomnessPower = 3;
    parameters.insideColor = "#ff6030";
    parameters.outsideColor = "#1b3984";

    let geometry = null;
    let material = null;
    let points = null;

    const generateGalaxy = () => {
      if (points !== null) {
        geometry.dispose();
        material.dispose();
        sceneRef.current.remove(points);
      }
      // Geometry******
      geometry = new THREE.BufferGeometry();

      const positions = new Float32Array(parameters.count * 3);
      const randomness = new Float32Array(parameters.count * 3);
      const colors = new Float32Array(parameters.count * 3);
      const scales = new Float32Array(parameters.count * 1);
      // *************

      const insideColor = new THREE.Color(parameters.insideColor);
      const outsideColor = new THREE.Color(parameters.outsideColor);

      for (let i = 0; i < parameters.count; i++) {
        const i3 = i * 3;

        // Position
        const radius = Math.random() * parameters.radius;

        const branchAngle =
          ((i % parameters.branches) / parameters.branches) * Math.PI * 2;

        const randomX =
          Math.pow(Math.random(), parameters.randomnessPower) *
          (Math.random() < 0.5 ? 1 : -1) *
          parameters.randomness *
          radius;
        const randomY =
          Math.pow(Math.random(), parameters.randomnessPower) *
          (Math.random() < 0.5 ? 1 : -1) *
          parameters.randomness *
          radius;
        const randomZ =
          Math.pow(Math.random(), parameters.randomnessPower) *
          (Math.random() < 0.5 ? 1 : -1) *
          parameters.randomness *
          radius;

        positions[i3] = Math.cos(branchAngle) * radius;
        positions[i3 + 1] = 0;
        positions[i3 + 2] = Math.sin(branchAngle) * radius;

        randomness[i3] = randomX;
        randomness[i3 + 1] = randomY;
        randomness[i3 + 2] = randomZ;

        // Color
        const mixedColor = insideColor.clone();
        mixedColor.lerp(outsideColor, (1.2 * radius) / parameters.radius);

        colors[i3] = mixedColor.r;
        colors[i3 + 1] = mixedColor.g;
        colors[i3 + 2] = mixedColor.b;

        // Scale
        scales[i] = Math.random();
      }

      geometry.setAttribute(
        "position",
        new THREE.BufferAttribute(positions, 3)
      );
      geometry.setAttribute(
        "aRandomness",
        new THREE.BufferAttribute(randomness, 3)
      );
      geometry.setAttribute("color", new THREE.BufferAttribute(colors, 3));
      geometry.setAttribute("aScale", new THREE.BufferAttribute(scales, 1));

      // Material
      material = new THREE.ShaderMaterial({
        depthWrite: false,
        blending: THREE.AdditiveBlending,
        vertexColors: true,
        uniforms: {
          uTime: { value: 0 },
          // uSize: { value: 1000 * renderer.getPixelRatio() },
          uSize: { value: 3000 },
        },
        vertexShader,
        fragmentShader,
      });

      // Points
      points = new THREE.Points(geometry, material);
      sceneRef.current.add(points);
    };

    /**
     * Generate the first galaxy
     */
    generateGalaxy();

    //  Animate *****************************************
    // Pointer Cordinates
    const pointer = new THREE.Vector2();

    function onPointerMove(event) {
      if (
        0 < event.clientX &&
        event.clientX < heroBounds.width &&
        0 < event.clientY &&
        event.clientY < heroBounds.height
      ) {
        pointer.x = event.clientX - heroBounds.width / 2;
        pointer.y = -(event.clientY - heroBounds.height / 2);
      } else {
        pointer.x = 0;
        pointer.y = 0;
      }
    }

    const clock = new THREE.Clock();

    const tick = () => {
      const elapsedTime = clock.getElapsedTime();

      // Update material
      material.uniforms.uTime.value = 10 * elapsedTime;

      // Update xamera
      cameraRef.current.position.lerp(
        {
          x: 0.2 * pointer.x,
          y: 3 * pointer.y,
          z: 600,
        },
        0.1
      );
      cameraRef.current.lookAt(0, 0, 0);

      if (rendereState) {
        rendererRef.current.render(sceneRef.current, cameraRef.current);
      }

      window.requestAnimationFrame(tick);
    };
    tick();

    window.addEventListener("pointermove", onPointerMove);

    window.addEventListener("resize", resizeHandeler);
    // **********************************************
  }, []);

  // Resize Handeler ****************************************

  const resizeHandeler = () => {
    const heroBounds = heroRef.current.getBoundingClientRect();
    setCanvas(heroBounds);
    setCamera(heroBounds);
    setRenderer(heroBounds);

    rendererRef.current.render(sceneRef.current, cameraRef.current);
  };

  const setCanvas = (heroBounds) => {
    const heroOffsetFromTop = heroRef.current.getBoundingClientRect().top;
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    const newCanvasTopPosition = heroOffsetFromTop + scrollPosition;

    canvasRef.current.style.top = `${newCanvasTopPosition}px`;
    canvasRef.current.style.left = `${heroBounds.left}px`;
    canvasRef.current.style.width = `${heroBounds.width}px`;
    canvasRef.current.style.height = `${heroBounds.height}px`;
  };

  const setRenderer = (heroBounds) => {
    rendererRef.current.setSize(heroBounds.width, heroBounds.height);
  };

  const setCamera = (heroBounds) => {
    const fov =
      ((2 * 180) / Math.PI) * Math.atan(heroBounds.height / 2 / cameraDistance);
    cameraRef.current.aspect = heroBounds.width / heroBounds.height;
    cameraRef.current.fov = fov;
    cameraRef.current.updateProjectionMatrix();
  };

  // ******************************************************
  return;
};
export default Galaxy;
