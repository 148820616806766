import "./App.css";
import MainHtml from "./components/MainHtml";
import Galaxy from "./components/Galaxy";
import WaveEffect from "./components/WaveEffect";
import SelectedImgOverlay from "./components/SelectedImgOverlay";
import SamplerParticles from "./components/SamplerParticles";

function App() {
  return (
    <>
      <MainHtml />
      <canvas id="heroSectionCanvas" className="fullScreenCanvas" />
      <canvas id="gallerySectionCanvas" className="gallerySectionCanvas" />
      <canvas
        id="samplereParticlesCanvas"
        className="samplereParticlesCanvas displayNone"
      />
      <Galaxy />
      <WaveEffect />
      <SamplerParticles />
      <SelectedImgOverlay />
    </>
  );
}

export default App;
