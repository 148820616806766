import * as THREE from "three";
import { useEffect, useRef } from "react";
import vertexShader from "./samplerShaders/vertexShader.glsl";
import fragmentShader from "./samplerShaders/fragmentShader.glsl";

import { MeshSurfaceSampler } from "three/addons/math/MeshSurfaceSampler.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import { ShaderPass } from "three/addons/postprocessing/ShaderPass.js";
import { LuminosityShader } from "three/addons/shaders/LuminosityShader.js";
import { gsap } from "gsap";
import { OBJLoader } from "three/addons/loaders/OBJLoader.js";
import { useSelector } from "react-redux";

const SamplerParticles = () => {
  const sceneRef = useRef(null);
  const rendererRef = useRef(null);
  const cameraRef = useRef(null);
  const canvasRef = useRef(null);
  const sectionRef = useRef(null);
  const sectionBoundsRef = useRef(null);
  const cameraDistance = 500;
  const materialRef = useRef(null);
  const geometryRef = useRef(null);
  const pointsRef = useRef(null);
  const composerRef = useRef(null);
  const renderSceneRef = useRef(null);

  const samplerRef = useRef(null);

  const numberOfSamples = 1000;
  const t1 = 4; //distortionPeriod
  const t2 = 5; //staticPeriod
  const numberOfModels = 4;
  const tT = numberOfModels * (t1 + t2); //animationDuration

  const loader = new OBJLoader();

  // Environment ******************************

  useEffect(() => {
    init();

    window.addEventListener("resize", resizeHandler);

    Promise.all([
      loadModel("./models/turtle.obj"),
      loadModel("./models/dolphin.obj"),
      loadModel("./models/rabit.obj"),
      loadModel("./models/penguin.obj"),
    ])
      .then((models) => {
        let samples = models.map((model) =>
          getMeshSurfaceSamplesPositions(model, numberOfSamples)
        );
        geometryRef.current = new THREE.BufferGeometry();

        materialRef.current = new THREE.ShaderMaterial({
          vertexShader,
          fragmentShader,
          side: THREE.DoubleSide,
          depthWrite: false,
          blending: THREE.AdditiveBlending,
          vertexColors: true,
          uniforms: {
            uTime: { value: 0 },
            uDistortion: { value: 1.0 },
            uScale: { value: 1 },
            uNumberOfSamples: { value: numberOfSamples },
            uTurtuleSamplePositions: { value: samples[0].positions },
            uDolphinSamplePositions: { value: samples[1].positions },
            uRabitSamplePositions: { value: samples[2].positions },
            uCubeSamplePositions: { value: samples[3].positions },
            uPhases: { value: [0, 0, 0, 0] },
            uProgress: { value: 0.0 },
          },
          wireframe: false,

          alphaTest: {},
        });

        geometryRef.current.setAttribute(
          "position",
          new THREE.BufferAttribute(samples[0].positions, 3)
        );

        pointsRef.current = new THREE.Points(
          geometryRef.current,
          materialRef.current
        );
        sceneRef.current.add(pointsRef.current);
        samples = [];
        models = [];
      })
      .catch((error) => {
        console.error("Error loading models:", error);
      });
  }, []);

  const loadModel = (url) => {
    return new Promise((resolve, reject) => {
      loader.load(
        url,
        (obj) => {
          const model = obj.children[0];

          model.material = new THREE.MeshBasicMaterial({
            wireframe: true,
            color: 0xff00ff,
            transparent: true,
            opacity: 0,
          });

          model.scale.set(0.5, 0.5, 0.5);
          model.updateMatrix();

          resolve(model);
        },
        undefined,
        reject
      );
    });
  };

  const loadCube = () => {
    return new Promise((resolve, reject) => {
      const cubGeometry = new THREE.BoxGeometry(1, 1, 1);
      const cubMaterial = new THREE.MeshBasicMaterial({
        color: 0x66ccff,
        wireframe: true,
      });
      const model = new THREE.Mesh(cubGeometry, cubMaterial);

      const scale = 200;

      model.scale.set(scale, scale, scale);
      model.updateMatrix();

      resolve(model);
    });
  };

  const getMeshSurfaceSamplesPositions = (mesh, numberOfSamples) => {
    samplerRef.current = new MeshSurfaceSampler(mesh).build();

    const positions = new Float32Array(numberOfSamples * 3);
    const uvs = new Float32Array(numberOfSamples * 2);

    const tempPosition = new THREE.Vector3();
    const matrixWorld = new THREE.Matrix4().copy(mesh.matrixWorld); // Get the world matrix of the mesh

    for (let i = 0; i < numberOfSamples; i++) {
      samplerRef.current.sample(tempPosition);

      // Apply the scale of the object to the sampled position
      tempPosition.multiply(mesh.scale).applyMatrix4(matrixWorld);

      positions[i * 3] = tempPosition.x;
      positions[i * 3 + 1] = tempPosition.y;
      positions[i * 3 + 2] = tempPosition.z;

      const u =
        (tempPosition.x - mesh.position.x + mesh.scale.x / 2) / mesh.scale.x;
      const v =
        (tempPosition.y - mesh.position.y + mesh.scale.y / 2) / mesh.scale.y;

      uvs[i * 2] = u;
      uvs[i * 2 + 1] = v;
    }

    return { positions, uvs };
  };

  const init = () => {
    // Canvas
    sectionRef.current = document.getElementById("samplerParticlesDiv");
    canvasRef.current = document.getElementById("samplereParticlesCanvas");

    sectionBoundsRef.current = sectionRef.current.getBoundingClientRect();

    setCanvas(sectionBoundsRef.current);

    // Renderer
    rendererRef.current = new THREE.WebGLRenderer({
      canvas: canvasRef.current,
      antialias: true,
      alpha: true,
    });

    setRenderer(sectionBoundsRef.current);
    rendererRef.current.setClearAlpha(0);
    rendererRef.current.setClearColor(0x000000, 0); // Set the clear color with alpha set to 0
    rendererRef.current.autoClear = false; // Disable the automatic clearing of the renderer
    rendererRef.current.sortObjects = false; // Disable object sorting for transparency

    // Scene
    sceneRef.current = new THREE.Scene();
    sceneRef.current.background = new THREE.Color(0x000000);

    // Camera
    cameraRef.current = new THREE.PerspectiveCamera(
      75,
      sectionBoundsRef.current.width / sectionBoundsRef.current.height,
      0.1,
      1000
    );
    cameraRef.current.position.set(0, 0, cameraDistance);

    setCamera(sectionBoundsRef.current);

    sceneRef.current.add(cameraRef.current);

    // Add post processing**********************************

    const bloomPass = new UnrealBloomPass(
      new THREE.Vector2(
        sectionBoundsRef.current.width,
        sectionBoundsRef.current.height
      ),
      0.1,
      0.8,
      1
    );
    composerRef.current = new EffectComposer(rendererRef.current);

    renderSceneRef.current = new RenderPass(
      sceneRef.current,
      cameraRef.current
    );

    composerRef.current.addPass(renderSceneRef.current);
    composerRef.current.addPass(bloomPass);

    // const luminosityPass = new ShaderPass(LuminosityShader);
    // composerRef.current.addPass(luminosityPass);

    // Animation
    const clock = new THREE.Clock();
    const animate = () => {
      const elapsedTime = clock.getElapsedTime();

      if (pointsRef.current) {
        pointsRef.current.rotation.y = elapsedTime;
        materialRef.current.uniforms.uTime.value = 0.5 * elapsedTime;
        // spheresRef.current.instanceMatrix.needsUpdate = true;
        // rendererRef.current.render(sceneRef.current, cameraRef.current);

        composerRef.current.render(sceneRef.current, cameraRef.current);

        //   // Apply the rotation to each instance of the InstancedMesh

        //   // Apply the same rotation as the cube

        if (elapsedTime % (t1 + t2) < t1) {
          materialRef.current.uniforms.uDistortion.value = 0;
        }
        if (
          elapsedTime % (t1 + t2) > t1 &&
          elapsedTime % (t1 + t2) < t1 + t2 / 2
        ) {
          gsap.to(materialRef.current.uniforms.uDistortion, {
            duration: t2 / 2,
            value: 1,
            ease: "power2.inout",
          });
        }
        if (elapsedTime % (t1 + t2) > t1 + t2 / 2) {
          gsap.to(materialRef.current.uniforms.uDistortion, {
            duration: t2 / 2,
            value: 0,
            ease: "power2.inout",
          });
        }

        let tN = elapsedTime % tT; //normalized elaped tome

        if (tN < t1 + t2) {
          materialRef.current.uniforms.uPhases.value = [1, 0, 0, 0];
        } else if (tN < 2 * (t1 + t2)) {
          materialRef.current.uniforms.uPhases.value = [0, 1, 0, 0];
        } else if (tN < 3 * (t1 + t2)) {
          materialRef.current.uniforms.uPhases.value = [0, 0, 1, 0];
        } else if (tN < 4 * (t1 + t2)) {
          materialRef.current.uniforms.uPhases.value = [0, 0, 0, 1];
        }

        if (elapsedTime % (t1 + t2) <= t1) {
          materialRef.current.uniforms.uProgress.value = 0;
        } else {
          materialRef.current.uniforms.uProgress.value =
            ((elapsedTime % (t1 + t2)) - t1) / t2;
        }
      }

      requestAnimationFrame(animate);
    };
    resizeHandler();
    animate();
  };

  // *************************************************

  // Resize Handeler ****************************************

  const resizeHandler = () => {
    const sectionBounds = sectionRef.current.getBoundingClientRect();
    setCanvas(sectionBounds);
    setCamera(sectionBounds);
    setRenderer(sectionBounds);

    rendererRef.current.render(sceneRef.current, cameraRef.current);
  };

  const setCanvas = (sectionBounds) => {
    const sectionOffsetFromTop = sectionBounds.top;
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    const newCanvasTopPosition = sectionOffsetFromTop + scrollPosition;

    canvasRef.current.style.top = `${newCanvasTopPosition}px`;
    canvasRef.current.style.left = `${sectionBounds.left}px`;
    canvasRef.current.style.width = `${sectionBounds.width}px`;
    canvasRef.current.style.height = `${sectionBounds.height}px`;
  };

  const setRenderer = (sectionBounds) => {
    rendererRef.current.setSize(sectionBounds.width, sectionBounds.height);
  };

  const setCamera = (sectionBounds) => {
    const fov =
      ((2 * 180) / Math.PI) *
      Math.atan(sectionBounds.height / 2 / cameraDistance);
    cameraRef.current.aspect = sectionBounds.width / sectionBounds.height;
    cameraRef.current.fov = fov;
    cameraRef.current.updateProjectionMatrix();
  };

  return null;
};

export default SamplerParticles;
