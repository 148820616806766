import { useDispatch } from "react-redux";
import { setData } from "../store/dataSlice";

const useFetchAndDisplay = () => {
  const dispatch = useDispatch();
  const apiKey = "HudSZUy1umSwSWLVUpsARGvnU3WCQnHrZvu1QR5r";

  const fetchDataAndDisplay = async (date) => {
    const url = `https://api.nasa.gov/planetary/apod?api_key=${apiKey}&date=${date}`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch NASA images");
      }

      const data = await response.json();

      if (!data) {
        // Handle the case when data is null or undefined
      } else {
        dispatch(setData(data));

        const selectedImgOverlay =
          document.getElementById("selectedImgOverlay");
        selectedImgOverlay.classList.remove("invisible");
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  return fetchDataAndDisplay;
};

export default useFetchAndDisplay;
