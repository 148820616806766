import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToGallery } from "../store/gallerySlice";
import "./selectedImgOverlay.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
  faFloppyDisk,
  faExpand,
} from "@fortawesome/free-solid-svg-icons";

const SelectedImgOverlay = () => {
  const dispatch = useDispatch();

  const handleCloseBtn = (e) => {
    e.preventDefault();
    const selectedImgOverlay = document.getElementById("selectedImgOverlay");
    selectedImgOverlay.classList.add("invisible");
  };

  const data = useSelector((state) => state.data);
  const { title, explanation, url } = data;

  const handleFullScreenRequest = () => {
    const selectedImg = document.getElementById("selectedImg");
    selectedImg.requestFullscreen();
  };

  const handleSaveRequest = () => {
    dispatch(addToGallery(data));
  };

  useEffect(() => {
    const imgOverlaySaveBtn = document.getElementById("imgOverlaySaveBtn");
    const imgOverlayFullScreenBtn = document.getElementById(
      "imgOverlayFullScreenBtn"
    );
    const imgOverlayCloseBtn = document.getElementById("imgOverlayCloseBtn");

    imgOverlaySaveBtn.addEventListener("click", handleSaveRequest);
    imgOverlayFullScreenBtn.addEventListener("click", handleFullScreenRequest);
    imgOverlayCloseBtn.addEventListener("click", handleCloseBtn);

    let localGalleryFromNasa = JSON.parse(
      localStorage.getItem("localGalleryFromNasa")
    );

    if (localGalleryFromNasa) {
      let isAlreadySaved = localGalleryFromNasa.some(
        (item) => item.date === data.date
      );
      if (isAlreadySaved) {
        imgOverlaySaveBtn.classList.add("savedBtn");
      } else if (!isAlreadySaved) {
        imgOverlaySaveBtn.classList.remove("savedBtn");
      }
    }

    return () => {
      imgOverlaySaveBtn.removeEventListener("click", handleSaveRequest);
      imgOverlayFullScreenBtn.removeEventListener(
        "click",
        handleFullScreenRequest
      );
      imgOverlayCloseBtn.removeEventListener("click", handleCloseBtn);
    };
  }, [data]);

  return (
    <>
      <div
        className="selectedImgOverlay invisible"
        id="selectedImgOverlay"
        style={{ top: "0" }}
      >
        <div className="wrapper">
          <div className="container">
            <div className="innerContainer">
              <div className="closeBtn" id="imgOverlayCloseBtn">
                <FontAwesomeIcon icon={faXmark} />
              </div>
              <div className="cell-1">
                <div className="selectedImgIcons" id="selectedImgIcons">
                  <div className="icons">
                    <span id="imgOverlayFullScreenBtn">
                      <FontAwesomeIcon icon={faExpand} />
                    </span>
                    <span id="imgOverlaySaveBtn">
                      <FontAwesomeIcon icon={faFloppyDisk} />
                    </span>
                  </div>
                  <img id="selectedImg" src={url} alt="" />
                </div>
              </div>
              <div className="cell-2">
                <div className="imgTitle">
                  <h2 id="imgTitle">{title}</h2>
                </div>
                <div className="imgDesc">
                  <p id="imgDesc">{explanation}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectedImgOverlay;
