import * as THREE from "three";
import { useEffect, useRef } from "react";
import vertexShader from "./waveEffectShaders/vertex.glsl";
import fragmentShader from "./waveEffectShaders/fragment.glsl";
import { useSelector } from "react-redux";
import { gsap } from "gsap";
import SvgParticles from "./SvgParticles";
import StarsBg from "./StarsBg";

const WaveEffect = () => {
  const meshesRef = useRef([]);
  const imagesRef = useRef([]);
  const sceneRef = useRef(null);
  const rendererRef = useRef(null);
  const cameraRef = useRef(null);
  const canvasRef = useRef(null);
  const galleryRef = useRef(null);
  const starsRef = useRef(null);
  const cameraDistance = 500;

  const galleryArray = useSelector((state) => state.gallery.array);
  const rendereState = useSelector((state) => state.renderers.rendererMain);

  useEffect(() => {
    const init = () => {
      // Environment ******************************

      // Canvas
      galleryRef.current = document.getElementById("gallerySection");
      canvasRef.current = document.getElementById("gallerySectionCanvas");

      const galleryBounds = galleryRef.current.getBoundingClientRect();

      setCanvas(galleryBounds);

      // Renderer
      rendererRef.current = new THREE.WebGLRenderer({
        canvas: canvasRef.current,
        antialias: true,
        alpha: true,
      });

      setRenderer(galleryBounds);
      rendererRef.current.setClearAlpha(0);
      rendererRef.current.setClearColor(0x000000, 0); // Set the clear color with alpha set to 0
      rendererRef.current.autoClear = false; // Disable the automatic clearing of the renderer
      rendererRef.current.sortObjects = false; // Disable object sorting for transparency

      // Scene
      sceneRef.current = new THREE.Scene();
      sceneRef.current.background = new THREE.Color(0x000000);

      // Camera
      cameraRef.current = new THREE.PerspectiveCamera(
        75,
        galleryBounds.width / galleryBounds.height,
        400,
        600
      );
      cameraRef.current.position.set(0, 0, cameraDistance);

      setCamera(galleryBounds);

      sceneRef.current.add(cameraRef.current);

      // *****************************************************

      // Background**************************************

      // Stars
      starsRef.current = StarsBg(canvasRef.current, 200);
      sceneRef.current.add(starsRef.current);

      // Constellation

      const ariesSvg = SvgParticles("aries", [-600, -10, -10]);
      sceneRef.current.add(ariesSvg);

      const sagittariusSvg = SvgParticles("sagittarius", [600, -10, -10]);
      // const sagittariusSvg = SvgParticles("aries", [600, -10, -10]);
      sceneRef.current.add(sagittariusSvg);

      const geminiSvg = SvgParticles("gemini", [0, 10, -10]);
      sceneRef.current.add(geminiSvg);

      // Animation *******************************

      const clock = new THREE.Clock();

      const animate = () => {
        const elapsedTime = clock.getElapsedTime();
        meshesRef.current.forEach(
          (mesh) => (mesh.material.uniforms.uTime.value = elapsedTime)
        );

        if (rendereState) {
          rendererRef.current.render(sceneRef.current, cameraRef.current);
        }

        requestAnimationFrame(animate);
      };

      // *************************************************

      resizeHandler();
      animate();

      // Windows Event Listener
      window.addEventListener("resize", resizeHandler);
      // **********************************************************
    };

    init();
  }, []);

  // Resize Handeler ****************************************

  const resizeHandler = () => {
    const galleryBounds = galleryRef.current.getBoundingClientRect();
    setCanvas(galleryBounds);
    setCamera(galleryBounds);
    setRenderer(galleryBounds);
    updateMeshPositions(galleryBounds);

    sceneRef.current.remove(starsRef.current);
    starsRef.current = StarsBg(canvasRef.current, 200);
    sceneRef.current.add(starsRef.current);

    rendererRef.current.render(sceneRef.current, cameraRef.current);
  };

  const setCanvas = (galleryBounds) => {
    const galleryOffsetFromTop = galleryRef.current.getBoundingClientRect().top;
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    const newCanvasTopPosition = galleryOffsetFromTop + scrollPosition;

    canvasRef.current.style.top = `${newCanvasTopPosition}px`;
    canvasRef.current.style.left = `${galleryBounds.left}px`;
    canvasRef.current.style.width = `${galleryBounds.width}px`;
    canvasRef.current.style.height = `${galleryBounds.height}px`;
  };

  const setRenderer = (galleryBounds) => {
    rendererRef.current.setSize(galleryBounds.width, galleryBounds.height);
  };

  const setCamera = (galleryBounds) => {
    const fov =
      ((2 * 180) / Math.PI) *
      Math.atan(galleryBounds.height / 2 / cameraDistance);
    cameraRef.current.aspect = galleryBounds.width / galleryBounds.height;
    cameraRef.current.fov = fov;
    cameraRef.current.updateProjectionMatrix();
  };

  // ******************************************************

  // Images and corresponding Meshes **********************

  const createMesh = (img, texture) => {
    const bounds = img.getBoundingClientRect();
    const scaleX = bounds.width;
    const scaleY = bounds.height;

    const geometry = new THREE.PlaneGeometry(1, 1, 10, 10);
    const material = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader,
      uniforms: {
        uImg: { value: texture },
        uTime: { value: 0 },
        uHoverState: { value: 0 },
        uHover: { value: new THREE.Vector2(0.5, 0.5) },
      },
      side: THREE.DoubleSide,
    });
    const mesh = new THREE.Mesh(geometry, material);

    mesh.scale.set(scaleX, scaleY, 1);
    mesh.userData.img = img;
    mesh.userData.id = img.id;

    // Img Event Listeners *********************
    const handleMouseEnter = () => {
      gsap.to(material.uniforms.uHoverState, {
        duration: 1,
        value: 1,
      });
    };

    const handleMouseLeave = () => {
      gsap.to(material.uniforms.uHoverState, {
        duration: 1,
        value: 0,
      });
    };

    const handleMouseMove = (event) => {
      const mouseX = event.clientX - bounds.left;
      const mouseY = event.clientY - bounds.top;
      const normalizedX = mouseX / bounds.width;
      const normalizedY = 1 - mouseY / bounds.height;
      mesh.material.uniforms.uHover.value.set(normalizedX, normalizedY);
    };

    img.addEventListener("mouseenter", handleMouseEnter);
    img.addEventListener("mouseleave", handleMouseLeave);
    img.addEventListener("mousemove", handleMouseMove);
    //*****************************************

    meshesRef.current.push(mesh);
    sceneRef.current.add(mesh);
  };

  // *******************************************************
  // Meshes Position Update *******************************

  const updateMeshPositions = (galleryBounds) => {
    meshesRef.current.forEach((mesh) => {
      const img = mesh.userData.img;
      const bounds = img.getBoundingClientRect();

      mesh.position.y =
        galleryBounds.top +
        galleryBounds.height / 2 -
        (bounds.top + bounds.height / 2);

      mesh.position.x =
        bounds.left + bounds.width / 2 - galleryBounds.width / 2;
      mesh.position.z = 0;
    });
  };
  // ************************************************************

  useEffect(() => {
    const gallerySection = document.getElementById("gallerySection");
    imagesRef.current = [...gallerySection.querySelectorAll("img")];

    const newImages = [];
    const meshesToDelete = [];

    meshesRef.current.forEach((mesh) => {
      const meshId = mesh.userData.id;
      const hasCorrespondingImg = imagesRef.current.some(
        (img) => img.id === meshId
      );

      if (!hasCorrespondingImg) {
        meshesToDelete.push(mesh);
      }
    });

    // Remove meshesToDelete from meshesRef
    meshesRef.current = meshesRef.current.filter(
      (mesh) => !meshesToDelete.includes(mesh)
    );

    meshesToDelete.forEach((mesh) => {
      // Remove mesh from scene
      sceneRef.current.remove(mesh);
      // Dispose mesh geometry and material to free up memory
      mesh.geometry.dispose();
      mesh.material.dispose();
      updateMeshPositions(gallerySection.getBoundingClientRect());
    });

    imagesRef.current.forEach((image) => {
      const imageId = image.id;
      const hasCorrespondingMesh = meshesRef.current.some(
        (mesh) => mesh.userData.id === imageId
      );

      if (!hasCorrespondingMesh) {
        newImages.push(image);
      }
    });

    if (newImages.length > 0) {
      const imagePromises = newImages.map((img) => {
        return new Promise((resolve, reject) => {
          const textureLoader = new THREE.TextureLoader();
          textureLoader.load(
            img.src,
            (texture) => {
              createMesh(img, texture);

              resolve();
            },
            undefined,
            (error) => {
              reject(error);
            }
          );
        });
      });

      // Wait for all image promises to resolve
      Promise.all(imagePromises)
        .then(() => {
          // All images have been loaded and added to the scene
          updateMeshPositions(gallerySection.getBoundingClientRect());
          rendererRef.current.render(sceneRef.current, cameraRef.current);
        })
        .catch((error) => {
          console.error("Error loading new images:", error);
        });
    }
    resizeHandler();
  }, [galleryArray]);

  return null;
};

export default WaveEffect;
